import React, { useState, useContext } from "react"
import styled from "styled-components"
import axios from "axios"
import { navigate } from "gatsby"
import useButtonLoader from "../../hooks/useButtonLoader"
import { NotificationManager } from "react-notifications"
import { Icon } from "rsuite"
import {
  COLOR_DEFAULT_TEXT_DARK,
  COLOR_DEFAULT_TEXT_LIGHT,
  COLOR_SEARCH_INPUT_TEXT,
  COLOR_DEFAULT_TEXT_DISABLED,
} from "../../constants/colors"
import {
  MOBILE,
  LARGE,
  MEDIUM,
  TAB,
  LANDSCAPE,
} from "../../constants/breakpoints"

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.publicPages.signin.width}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.publicPages.signin.width}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.publicPages.signin.width}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.publicPages.signin.width}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.publicPages.signin.width}px;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  margin: 10px auto;
  width: 100%;
`

const FormHeading = styled.h3`
  padding-top: 1.42vh;
  display: flex;
  justify-content: center;
  color: #eeeeee;
  font-weight: normal;
  margin: 0;
  ::selection {
    color: none;
    background: none;
  }
  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: ${MOBILE.publicPages.signin.font.heading}px;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    font-size: ${LANDSCAPE.publicPages.signin.font.heading}px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.publicPages.signin.font.heading}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: ${MEDIUM.publicPages.signin.font.heading}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    font-size: ${LARGE.publicPages.signin.font.heading}px;
  }
  margin-bottom: 25px;
`
const FormInput = styled.input`
  height: 50px;
  width: 100%;
  border: 1px solid #989dad;
  border-radius: 10px;
  outline: none;
  box-shadow: none;
  color: #fff;
  margin: 5px 0px;
  background: none;
  padding: 10px;
  font-size: 18px;
  ::placeholder {
    color: ${COLOR_SEARCH_INPUT_TEXT};
  }
`

const FormHelpText = styled.div`
  margin: 0;
  padding: 10px 15px;
  font-size: 20px;
  color: ${COLOR_DEFAULT_TEXT_LIGHT};
  & a {
    color: ${COLOR_DEFAULT_TEXT_LIGHT};
    &:hover {
      text-decoration: none;
      color: ${COLOR_DEFAULT_TEXT_LIGHT};
    }
  }
`
const ForgotPasswordText = styled.div`
  margin: 0;
  padding: 0px;
  text-align: right;
  font-size: 16px;
  color: ${COLOR_DEFAULT_TEXT_LIGHT};
  & a {
    color: ${COLOR_DEFAULT_TEXT_LIGHT};
    &:hover {
      text-decoration: none;
      color: ${COLOR_DEFAULT_TEXT_LIGHT};
    }
  }
`
const FormSubmitButton = styled.button`
  background-color: #ffffff;
  height: auto;
  font-size: 18px;
  border-radius: 5px;
  align-self: flex-end;
  color: ${COLOR_DEFAULT_TEXT_DARK};
  ::selection {
    color: none;
    background: none;
    border: none;
  }
  :disabled {
    color: ${COLOR_DEFAULT_TEXT_DISABLED};
  }

  padding: 5px 10px;
  @media (min-width: ${TAB.minWidth}px) {
    padding: 5px 30px;
  }
`
const ErrorMessage = styled.p`
  display: block;
  opacity: ${props => (props.hasData ? "1" : "0")};
  color: #9e0101;
  background: #fff;
  font-size: 1.5rem;
  border-left: 4px solid #9e0101;
  padding: 5px 15px;
  transition: opacity 500ms linear;
  @media (min-width: ${TAB.minWidth}px) {
    padding: 10px 30px;
  }
`
const FormHelpers = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  padding-bottom: 20px;
  justify-content: space-between;
`
const PasswordWrapper = styled.div`
  display: flex;
  position: relative;
`
const EyeButton = styled(Icon)`
  font-size: ${MOBILE.box.iconSize}px !important;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 38%;
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    font-size: ${LANDSCAPE.box.iconSize}px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.box.iconSize}px;
  }
`
const PasswordHint = styled.span`
  margin-bottom: 10px;
`


const ContentResetPassword = props => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  })

  const [loaderElement, setLoader] = useButtonLoader("Change Password", "Processing...")

  const [submitted, setSubmitted] = useState(false)
  const [errorData, setErrorData] = useState("")
  const [displayError, setDisplayError] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const showError = error => {
    setErrorData(error)
    setDisplayError(true)
    setTimeout(() => {
      hideError()
    }, 3000)
  }

  const hideError = () => {
    setDisplayError(false)
  }

  const validateAndSend = e => {
    setLoader(true)
    e && e.preventDefault()
    if (!formData.newpassword) {
      showError("Please enter new password")
      // clear error after some time
      setLoader(false)
      return
    }
    if (!formData.confirmpassword) {
      showError("Please enter confirm password")
      // clear error after some time
      setLoader(false)
      return
    }
    if (formData.newpassword !== formData.confirmpassword) {
      showError("Password do not match")
      // clear error after some time
      setLoader(false)
      return
    }
    if (formData.newpassword) { 
      const passwordLetterCheck = "^(?=.*[a-z])(?=.*[A-Z])"
      const passwordLengthCheck = "^(?=.{8,})"
      const passwordNumberCheck = "^(?=.*[0-9])"
      if (!formData.newpassword.match(passwordLengthCheck)){
        showError("Password must have at least 8 characters")
        setLoader(false)
        return
      }
      if (!formData.newpassword.match(passwordLetterCheck)){
        showError("Password must have one uppercase and lowercase")
        setLoader(false)
        return
      }
      if (!formData.newpassword.match(passwordNumberCheck)){
        showError("Password must have one number")
        setLoader(false)
        return
      }
    }

    axios
      .post(`${process.env.STRAPI_SERVER_URL}/auth/reset-password`, {
        code: props.resetCode,
        password: formData.newpassword,
        passwordConfirmation: formData.confirmpassword,
      })
      .then(response => {
        // Handle success.
        NotificationManager.success("Password Changed successfully!")
        setLoader(false)
        navigate("/login")
      })
      .catch(error => {
        // Handle error.
        NotificationManager.error("Password not changed, please try again later!")
        setLoader(false)
      });
  }

  return (
    <LoginWrapper>
      <Form>
        <FormHeading>Reset your password</FormHeading>
        <PasswordWrapper>
          <FormInput
            type={showNewPassword ? "text" : "password"}
            placeholder="New Password"
            onChange={e => setFormData({ ...formData, newpassword: e.target.value })}
            onKeyUp={event => event.keyCode === 13 && validateAndSend()}
          />
          <EyeButton
            icon={showNewPassword ? "eye-slash" : "eye"}
            onClick={() => {
              setShowNewPassword(!showNewPassword)
            }}
          />
        </PasswordWrapper>
        <PasswordWrapper>
          <FormInput
            type={showConfirmPassword ? "text" : "password"}
            placeholder="Confirm Password"
            onChange={e => setFormData({ ...formData, confirmpassword: e.target.value })}
            onKeyUp={event => event.keyCode === 13 && validateAndSend()}
          />
          <EyeButton
            icon={showConfirmPassword ? "eye-slash" : "eye"}
            onClick={() => {
              setShowConfirmPassword(!showConfirmPassword)
            }}
          />
        </PasswordWrapper>
        <PasswordHint>
            Min. 8 characters, 1 upper, 1 lower, 1 number
          </PasswordHint>
        <FormHelpers>
          <FormSubmitButton
            ref={loaderElement}
            type="button"
            disabled={submitted}
            onSubmit={validateAndSend}
            onClick={validateAndSend}
          >
            Change Password
          </FormSubmitButton>
        </FormHelpers>
      </Form>
      <ErrorMessage hasData={displayError ? true : false}>
        {errorData}
      </ErrorMessage>
    </LoginWrapper>
  )
}

export default ContentResetPassword
